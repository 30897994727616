import { Button } from "ui/Button/Button";
import { Modal } from "ui/Modal/Modal";
import { getDeviceInfo, logConnectionType } from "../utils";

type Props = {
  isOpen: boolean;
  isInitialized: boolean;
  hasInitializationBeenTriggered: boolean;
  scannerId?: string;
  scanningName?: string;
  onClose: () => void;
};

export const ScannerStatusModal = ({
  isOpen,
  onClose,
  scannerId,
  scanningName,
  hasInitializationBeenTriggered,
  isInitialized,
}: Props) => {
  const deviceInfo = getDeviceInfo();
  const connection = logConnectionType();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCloseIconVisible={false}>
      <div className="px-2">
        <Modal.Title className="mb-5 text-center">Status skanera</Modal.Title>

        <div className="text-center flex flex-col gap-y-2">
          <p className="text-md font-medium flex flex-col">
            Skaner ID: <span className="font-normal">{scannerId}</span>
          </p>
          <p className="text-md font-medium flex flex-col">
            Nazwa skanera: <span className="font-normal">{scanningName}</span>
          </p>
          <p className="text-md font-medium flex flex-col">
            Status skanera:{" "}
            <span className="font-normal">
              {hasInitializationBeenTriggered && !isInitialized
                ? `Synchronizacja w trakcie - można skanować w trybie online (potrzebne połączenie z internetem)`
                : !hasInitializationBeenTriggered
                  ? "Synchronizacja nie została rozpoczęta"
                  : isInitialized
                    ? "Pełna synchronizacja - można skanować bez połączenia z internetem"
                    : "Brak statusu"}
            </span>
          </p>
          <p className="text-md font-medium flex flex-col">
            Przeglądarka:{" "}
            <span className="font-normal">{`${deviceInfo.browserName} - ${deviceInfo.fullBrowserVersion}`}</span>
          </p>
          <p className="text-md font-medium flex flex-col">
            Urządzenie:{" "}
            <span className="font-normal">{`${deviceInfo.osName} - ${deviceInfo.osVersion}`}</span>
          </p>
          <p className="text-md font-medium flex flex-col">
            {connection.connectionType !== "not supported"
              ? connection.connectionType
              : null}
            {connection.downlinkMax !== "not supported"
              ? connection.downlinkMax
              : null}
          </p>
        </div>
        <Modal.Actions>
          <Button onClick={onClose} size="small" variant="empty" type="button">
            Zamknij
          </Button>
        </Modal.Actions>
      </div>
    </Modal>
  );
};
