import { QrReader } from "react-qr-reader";
import { Link, useLocation } from "react-router-dom";
import { Button } from "ui/Button/Button";
import { toast } from "ui/Toaster/Toaster";

export const ViewFinder = () => {
  const color = "#6D6A85";
  return (
    <>
      <svg
        width="50px"
        viewBox="0 0 100 100"
        style={{
          top: 0,
          left: 0,
          zIndex: 1,
          boxSizing: "border-box",
          border: "50px solid rgba(0, 0, 0, 0.3)",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <path fill="none" d="M13,0 L0,0 L0,13" stroke={color} strokeWidth="5" />
        <path
          fill="none"
          d="M0,87 L0,100 L13,100"
          stroke={color}
          strokeWidth="5"
        />
        <path
          fill="none"
          d="M87,100 L100,100 L100,87"
          stroke={color}
          strokeWidth="5"
        />
        <path
          fill="none"
          d="M100,13 L100,0 87,0"
          stroke={color}
          strokeWidth="5"
        />
      </svg>
    </>
  );
};

export const EventQRPage = () => {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation as
    | Location
    | undefined;

  const onRead = (text: string) => {
    if (!text.startsWith("http")) {
      toast.error("Kod QR zawiera niepoprawny link");
    }
    const url = new URL(text);
    url.searchParams.set("isFromEventQRPage", "true");
    window.location.href = url.toString();
  };

  return (
    <>
      <div className="">
        {previousLocation ? (
          <Link to={previousLocation} className="m-auto">
            <Button
              variant="text"
              size="small"
              hasPadding={false}
              className="mb-4"
            >
              Strona startowa
            </Button>
          </Link>
        ) : null}

        <p className="">
          Zeskanuj kod QR wydarzenia wysłany na Twój adres e-mail:
        </p>
        <QrReader
          className="mt-4"
          constraints={{ facingMode: "environment" }}
          ViewFinder={ViewFinder}
          onResult={(result, error) => {
            if (!!result) {
              onRead((result as any)?.text);
            }

            if (
              error?.message &&
              error.message !== "Dimensions could be not found." // non important error
            ) {
              toast.error(error?.message);
            }
          }}
        />
      </div>
    </>
  );
};
