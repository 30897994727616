import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FieldHookConfig, useField } from "formik";
import {
  FC,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  Ref,
  useId,
  useState,
} from "react";

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactNode;
  type?: string;
  helperText?: ReactNode;
  iconRight?: FC<{ className?: string }>;
  trailingText?: string;
  error?: string;
  hideSpinButtons?: boolean;
  inputClassName?: string;
  dataTestId?: string;
};

const BasicInput = (
  {
    label,
    helperText,
    type = "text",
    iconRight: IconRight,
    className,
    required,
    value = "",
    error,
    trailingText,
    disabled,
    hideSpinButtons = false,
    inputClassName,
    dataTestId,
    ...inputElementProps
  }: Props,
  ref: Ref<HTMLInputElement>,
) => {
  const id = useId();
  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-normal text-stone-900">
        {label}
        {label && required ? "*" : ""}
      </label>
      <div className="relative mt-1 rounded-lgplus">
        <input
          ref={ref}
          type={type}
          value={value}
          {...inputElementProps}
          id={id}
          style={{ boxShadow: "none" }} // replace with tailwing, focus:shadow-none doesnt work
          className={classNames(
            "block w-full rounded-lgplus px-3 py-2.5 shadow-none focus:shadow-none sm:text-sm",
            IconRight && "pr-9",
            hideSpinButtons && "hide-webkit-spin-buttons",
            trailingText && "hide-webkit-spin-buttons", // in case of type=number hide spinner buttons,
            disabled
              ? "border-stone-400 text-stone-500 placeholder-stone-500"
              : error
              ? "border-ruby-600 text-ruby-800 placeholder-ruby-600 focus:border-ruby-600 focus:outline-none"
              : "border-stone-600 placeholder-stone-700 focus:border-tixy-400",
            inputClassName,
          )}
          required={required}
          disabled={disabled}
          data-testid={dataTestId}
        />
        {IconRight ? (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <IconRight
              className={classNames(
                "h-6 w-6",
                disabled
                  ? "text-stone-500"
                  : error
                  ? "text-ruby-800"
                  : "text-stone-700",
              )}
              aria-hidden="true"
            />
          </div>
        ) : null}
        {trailingText ? (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <span
              className={classNames(
                "sm:text-sm",
                disabled
                  ? "text-stone-500"
                  : error
                  ? "text-ruby-800"
                  : "text-stone-700",
              )}
            >
              {trailingText}
            </span>
          </div>
        ) : null}
      </div>
      {error ? <p className="mt-1 text-xs text-ruby-800">{error}</p> : null}
      {!error && helperText ? (
        <p className="mt-1 text-xs text-stone-700">{helperText}</p>
      ) : null}
    </div>
  );
};

export const Input = forwardRef(BasicInput);

type ConnectedInputProps = Omit<Props, "onChange" | "value"> &
  Pick<FieldHookConfig<string>, "name" | "validate">;
export const ConnectedInputWithRef = (
  props: ConnectedInputProps,
  ref: Ref<HTMLInputElement>,
) => {
  const [field, meta] = useField<string>(props);
  return (
    <Input
      {...field}
      {...props}
      error={meta.error && meta.touched ? meta.error : undefined}
      ref={ref}
    />
  );
};
export const ConnectedInput = forwardRef(ConnectedInputWithRef);

export const ConnectedPasswordInputWithRef = (
  props: ConnectedInputProps,
  ref: Ref<HTMLInputElement>,
) => {
  const [field, meta] = useField<string>(props);
  const [isTextVisible, setIsTextVisible] = useState(false);
  return (
    <Input
      {...field}
      {...props}
      iconRight={({ className }) => (
        <button
          className={className}
          onClick={() => setIsTextVisible(!isTextVisible)}
          type="button"
        >
          {isTextVisible ? <EyeSlashIcon /> : <EyeIcon />}
        </button>
      )}
      type={isTextVisible ? "text" : "password"}
      error={meta.error && meta.touched ? meta.error : undefined}
      ref={ref}
    />
  );
};
export const ConnectedPasswordInput = forwardRef(ConnectedPasswordInputWithRef);
