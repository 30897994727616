import classNames from "classnames";
import { useEffect, useRef } from "react";
import { isIOS } from "react-device-detect";
import { useTimeoutFn } from "react-use";
import { usePWAContext } from "shared/PWAContext/PWAContext";
import { Button } from "ui/Button/Button";
import { Modal } from "ui/Modal/Modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  ticketDetails?: {
    ticketName: string;
    ticketPoolName: string;
    ticketExInfo?: string;
    code?: string;
    ticketImportedForm?: string;
  };
};
export const SuccessModal = ({ isOpen, onClose, ticketDetails }: Props) => {
  const { isInsideTheApp } = usePWAContext();
  const initialFocusElementRef = useRef<HTMLDivElement>(null);
  const [, , resetTimeout] = useTimeoutFn(onClose, 3_000);

  useEffect(() => resetTimeout(), [resetTimeout, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => null}
      className="flex min-h-[calc(100dvh-32px)] flex-col overflow-visible"
      isCloseIconVisible={false}
      noPadding
      background="bg-tixy-1000"
      // We want to prevent action buttons to get any focus when modal opens up.
      // This prevents a bug where Zebra RedLight reader fires delayed "Enter" key
      // causing this modal to close before use have any chance to interact with the modal
      initialFocus={initialFocusElementRef}
    >
      <div ref={initialFocusElementRef} />
      <div className="flex grow items-center justify-center rounded-lgplus bg-sea-450 text-white">
        <div className="max-w-[250px] text-center">
          <Modal.SuccessIcon />
          <p className="text-3xl">Bilet poprawny</p>
          {ticketDetails ? (
            <>
              <p className="mt-6 inline-block rounded-lg bg-sea-200 px-3 pb-0.5 text-xl text-sea-500">
                {ticketDetails?.ticketName}
                {ticketDetails?.ticketExInfo
                  ? `: ${ticketDetails.ticketExInfo}`
                  : ""}
              </p>
              {ticketDetails?.ticketPoolName ? (
                <p className="mt-1 text-base text-sea-200">
                  {ticketDetails?.ticketPoolName}
                </p>
              ) : null}
              {ticketDetails?.ticketImportedForm ? (
                <p className="mt-1 text-base text-sea-200 break-all w-full">
                  Lista: {ticketDetails?.ticketImportedForm}
                </p>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      <Modal.Actions
        className={classNames(
          "!mt-4",
          isInsideTheApp && isIOS ? "pb-16" : "pb-4",
        )}
      >
        <Button onClick={onClose} size="small">
          Skanuj dalej
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
