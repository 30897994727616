const tokenPublicKey = process.env.REACT_APP_TOKEN_PUBLIC_KEY as string;
if (!tokenPublicKey) {
  throw new Error("REACT_APP_TOKEN_PUBLIC_KEY is missing!");
}

const awsExportsConfig = process.env.REACT_APP_AWS_EXPORTS_CONFIG as string;
if (!awsExportsConfig) {
  throw new Error("REACT_APP_AWS_EXPORTS_CONFIG is missing!");
}

const imageKitURL = process.env.REACT_APP_IMAGEKIT_URL as string;
if (!imageKitURL) {
  throw new Error("REACT_APP_IMAGEKIT_URL is missing!");
}

export { tokenPublicKey, awsExportsConfig, imageKitURL };
