import { useEffect, useState } from "react";
import { isChrome } from "react-device-detect";
import { QrReader, OnResultFunction } from "react-qr-reader";
import { Alert } from "ui/Alert/Alert";
import { toast } from "ui/Toaster/Toaster";
import { Loader } from "../../ui/Loader/Loader";

export const ViewFinder = () => {
  const color = "#6D6A85";
  return (
    <>
      <svg
        width="50px"
        viewBox="0 0 100 100"
        style={{
          top: 0,
          left: 0,
          zIndex: 1,
          boxSizing: "border-box",
          border: "50px solid rgba(0, 0, 0, 0.3)",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <path fill="none" d="M13,0 L0,0 L0,13" stroke={color} strokeWidth="5" />
        <path
          fill="none"
          d="M0,87 L0,100 L13,100"
          stroke={color}
          strokeWidth="5"
        />
        <path
          fill="none"
          d="M87,100 L100,100 L100,87"
          stroke={color}
          strokeWidth="5"
        />
        <path
          fill="none"
          d="M100,13 L100,0 87,0"
          stroke={color}
          strokeWidth="5"
        />
      </svg>
    </>
  );
};

export const useCamerAccess = () => {
  const [cameraAccessState, setCameraAccessState] = useState<
    "granted" | "denied" | "unknown"
  >("granted");

  useEffect(() => {
    const askForCameraAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (stream.getVideoTracks().length > 0) {
          console.log("AVAILABLE");
          setCameraAccessState("granted");
        } else {
          console.log("NOT AVAILABLE");
        }
      } catch (error) {
        console.error("Camera exception:", error);
        setCameraAccessState("denied");
      }
    };
    // this seems to be problematic on zebra devices https://stackoverflow.com/a/74313086/4443323
    askForCameraAccess();
  }, []);

  return {
    cameraAccessState,
    errorMessage:
      cameraAccessState === "denied" ? (
        <div className="mt-5">
          <Alert variant="error">
            Brak dostępu do kamery. Spróbuj odświeżyć stronę lub upewnij się, że
            przyznałeś dostęp do aparatu.
            {isChrome ? (
              <>
                <br />
                <br />
                Sprawdź{" "}
                <a
                  href="https://support.google.com/chrome/answer/2693767?hl=pl&co=GENIE.Platform%3DAndroid"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  tutaj
                </a>{" "}
                jak odblokować dostęp do aparatu.
              </>
            ) : null}
          </Alert>
        </div>
      ) : null,
  };
};

type Props = {
  onRead: (code: string) => void;
  className?: string;
  isCheckingTicketOnline?: boolean;
};
export const QRCameraReader = ({
  className,
  onRead,
  isCheckingTicketOnline = false,
}: Props) => {
  const handleResult: OnResultFunction = (result, error) => {
    if (!!result) {
      onRead((result as any)?.text);
    }

    if (
      error?.message &&
      error.message !== "Dimensions could be not found." // non important error
    ) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      {isCheckingTicketOnline ? <Loader /> : null}
      <QrReader
        className={className}
        constraints={{ facingMode: "environment" }}
        ViewFinder={ViewFinder}
        onResult={handleResult}
      />
    </>
  );
};
