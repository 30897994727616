import classNames from "classnames";
import { useId, useRef, useState } from "react";
import { Loader } from "../../ui/Loader/Loader";

type Props = {
  onRead: (code: string) => void;
  className?: string;
  isCheckingTicketOnline?: boolean;
};
export const QRRedLightReader = ({
  className,
  onRead,
  isCheckingTicketOnline = false,
}: Props) => {
  const inputId = useId();
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef("");

  const handleSubmit = () => {
    onRead(codeRef.current);
    codeRef.current = "";
  };

  return (
    <div className={className}>
      <label
        htmlFor={inputId}
        className={classNames(
          "flex h-[50vw] w-full items-center justify-center rounded-lgplus text-lg text-center px-2",
          isFocused ? "bg-transparent border-2 border-tixy-500" : "bg-tixy-500",
        )}
      >
        {isFocused ? (
          <p>
            Skaner gotowy.
            <br />
            Użyj przycisków skanera by zeskanować kod QR.
          </p>
        ) : (
          <p>Naciśnij by zacząć skanowanie</p>
        )}
      </label>
      {isCheckingTicketOnline ? <Loader /> : null}
      <div>
        <input
          autoFocus
          id={inputId}
          className="text-tixy-1000 w-full focus:ring-0 bg-transparent border-none active:border-none"
          ref={inputRef}
          name="ticketCode"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
              return;
            }
            if (e.key !== "Unidentified" && e.key !== "Shift") {
              codeRef.current = codeRef.current + e.key;
            }
          }}
          readOnly
        />
      </div>
    </div>
  );
};
