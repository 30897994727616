import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FC, ReactNode } from "react";

type Props = {
  className?: string;
  children: ReactNode;
  variant?: "info" | "warning" | "error" | "success";
  icon?: FC<any>;
};

export const Alert = ({
  children,
  className,
  variant = "info",
  icon,
}: Props) => {
  const Icon = icon
    ? icon
    : variant === "info"
    ? InformationCircleIcon
    : ExclamationTriangleIcon;
  return (
    <div
      className={classNames(
        "rounded-lgplus px-4 py-2",
        variant === "info"
          ? "bg-tixy-50 text-sm text-tixy-700"
          : variant === "warning"
          ? "bg-sand-200 text-sand-600"
          : variant === "error"
          ? "bg-ruby-650 text-white"
          : variant === "success"
          ? "bg-sea-450 text-white"
          : "",
        className,
      )}
    >
      <div className="flex">
        <Icon
          className={classNames(
            "h-5 w-5 flex-shrink-0",
            variant === "info" ? "text-tixy-500" : "mt-0.5",
          )}
          aria-hidden="true"
        />
        <p className="ml-3">{children}</p>
      </div>
    </div>
  );
};
