import classNames from "classnames";
import { format } from "date-fns";
import { Formik } from "formik";
import { LazyTicket } from "models";
import { useRef, useState } from "react";
import { isIOS } from "react-device-detect";
import { usePWAContext } from "shared/PWAContext/PWAContext";
import { FansExplanation, LogDetails, ScanningResult } from "shared/types";
import { Button } from "ui/Button/Button";
import { Modal } from "ui/Modal/Modal";
import { ConnectedRadioButtonGroup } from "ui/RadioButtonGroup/RadioButtonGroup";
import { ConnectedTextarea } from "ui/Textarea/Textarea";
import * as Yup from "yup";
import { readTicketLogs } from "./useLogger";

type FormValues = {
  fansExplanation?: FansExplanation;
  comment?: string;
};

type ContinueScanningHandler = (values?: FormValues) => void;

const errorTypeTranslation: Record<ScanningResult, string> = {
  [ScanningResult.InvalidTicketId]: "Niepoprawny kod biletu",
  [ScanningResult.InvalidQRCode]: "Niepoprawny kod QR",
  [ScanningResult.AlreadyScannedTicket]: "Bilet już zeskanowany",
  [ScanningResult.NonExistingTicket]: "Bilet nie istnieje",
  [ScanningResult.InactiveTicket]: "Bilet nieaktywny",
  [ScanningResult.TicketRevoked]: "Bilet anulowany",
  [ScanningResult.TicketScannedSuccessfully]: "",
};

type Props = {
  isOpen: boolean;
  errorType: ScanningResult;
  onHelp: () => void;
  onContinueScanning: ContinueScanningHandler;
  ticket?: LazyTicket;
  ticketDetails?: {
    ticketName: string;
    ticketPoolName: string;
    ticketExInfo?: string;
    code?: string;
    ticketImportedForm?: string;
  };
};
export const FailureModal = ({
  isOpen,
  errorType,
  onHelp,
  onContinueScanning,
  ticket,
  ticketDetails,
}: Props) => {
  const { isInsideTheApp } = usePWAContext();
  const [shouldDisplayErrorForm, setShouldDisplayErrorForm] = useState(false);
  const initialFocusElementRef = useRef<HTMLDivElement>(null);
  const validationSchema: Yup.Schema<FormValues> = Yup.object().shape({
    fansExplanation: Yup.mixed<FansExplanation>().oneOf(
      Object.values(FansExplanation),
    ),
    comment: Yup.string(),
  });

  const alreadyScannedBy = (() => {
    if (!ticket) {
      return;
    }

    const log = readTicketLogs(ticket).filter((item) => item.type !== "ticket-imported").sort((a,b) => {
      return (b.timestamp ?? 0) - (a.timestamp ?? 0);
    }).find(
      (log) =>
        log.type ===
        (ScanningResult.TicketScannedSuccessfully ||
          ScanningResult.AlreadyScannedTicket),
    );
    if (!log) {
      return;
    }
    const logDetails = JSON.parse(log.details || "{}") as LogDetails;

    return {
      scanningName: logDetails.scanningName!,
      timestamp: log.timestamp!,
    };
  })();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => null}
      className="flex min-h-[calc(100dvh-32px)] flex-col overflow-visible"
      isCloseIconVisible={false}
      noPadding
      background="bg-tixy-1000"
      afterIsClosed={() => {
        setShouldDisplayErrorForm(false);
      }}
      // We want to prevent action buttons to get any focus when modal opens up.
      // This prevents a bug where Zebra RedLight reader fires delayed "Enter" key
      // causing this modal to close before use have any chance to interact with the modal
      initialFocus={initialFocusElementRef}
    >
      <div ref={initialFocusElementRef} />
      {shouldDisplayErrorForm ? (
        <div className="flex grow rounded-lgplus bg-white">
          <div className="w-full px-4 pb-8 pt-4 text-center">
            <p className="text-3xl">Bilet niepoprawny</p>
            <p className="mt-3 inline-block rounded-lg bg-ruby-500 px-3 py-1 text-base text-ruby-700">
              {errorTypeTranslation[errorType]}
            </p>
            {ticketDetails ? (
              <>
                <p className="mt-4 text-center font-medium">
                  {ticketDetails?.ticketName}
                  {ticketDetails?.ticketExInfo
                    ? `: ${ticketDetails.ticketExInfo}`
                    : ""}
                </p>
                <p className="text-center text-sm text-stone-700">
                  {ticketDetails.ticketPoolName}
                </p>
                {ticketDetails?.code ? (
                  <p className="mt-1 text-sm text-sea-200">
                    {ticketDetails?.code}
                  </p>
                ) : null}
                {alreadyScannedBy ? (
                  <p className="mt-3 text-center text-sm text-stone-700">
                    Zeskanowane przez: {alreadyScannedBy.scanningName},{" "}
                    <span className="font-medium">
                      {format(
                        new Date(alreadyScannedBy.timestamp),
                        "dd/MM/yyyy HH:mm",
                      )}
                    </span>
                  </p>
                ) : null}
              </>
            ) : null}
            <Formik<FormValues>
              initialValues={{}}
              onSubmit={onContinueScanning}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, values, setFieldValue }) => (
                <form
                  onSubmit={handleSubmit}
                  className="mt-4 grid gap-form text-left"
                  noValidate
                >
                  <div className="flex items-center justify-between">
                    <p className="font-medium">Wytłumaczenie fana</p>
                    {values.fansExplanation ? (
                      <button
                        className="text-tixy-500 font-medium text-normal"
                        type="button"
                        onClick={() => setFieldValue("fansExplanation", "")}
                      >
                        Odznacz
                      </button>
                    ) : null}
                  </div>
                  <ConnectedRadioButtonGroup
                    name="fansExplanation"
                    wrapperClassName="grid-cols-1"
                    options={[
                      {
                        id: FansExplanation.BoughtWithTixy,
                        label: "Kupiony w oficjalnej sprzedaży TIXY",
                      },
                      {
                        id: FansExplanation.BoughtWithDifferentTicketOffice,
                        label: "Kupiony w innej bileterii niż TIXY",
                      },
                      {
                        id: FansExplanation.BoughtOnSecondaryMarket,
                        label: "Odkupiony na rynku wtórnym",
                      },
                      {
                        id: FansExplanation.PersonalDataChanged,
                        label: "Były zmieniane dane osobowe",
                      },
                      {
                        id: FansExplanation.BoughtUnderTheVenue,
                        label: "Odkupiony pod venue",
                      },
                      { id: FansExplanation.Other, label: "Inne" },
                    ]}
                  />
                  <p className="font-medium">Komentarz skanującego</p>
                  <ConnectedTextarea
                    name="comment"
                    label=""
                    placeholder="Zacznij pisać..."
                  />
                  <Modal.Actions>
                    <div className="flex items-center gap-2">
                      <Button
                        size="small"
                        variant="empty"
                        onClick={onHelp}
                        type="button"
                        className="flex-1"
                      >
                        Pomoc
                      </Button>
                      <Button
                        size="small"
                        variant="empty"
                        onClick={() => onContinueScanning(undefined)}
                        type="button"
                        className="flex-1"
                      >
                        Anuluj
                      </Button>
                    </div>
                    <Button size="small" type="submit">
                      {Object.values(values).filter(Boolean).length
                        ? "Wyślij"
                        : "Skanuj dalej"}
                    </Button>
                  </Modal.Actions>
                </form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <>
          <div className="flex grow rounded-lgplus bg-ruby-650 text-white">
            <div className="m-auto flex flex-col items-center justify-center text-center w-full">
              <Modal.ErrorIcon />
              <p className="text-3xl">Błąd</p>
              <p className="mt-3 rounded-lg bg-white px-3 py-1 text-xl text-ruby-650">
                {errorTypeTranslation[errorType]}
              </p>
              <p className="mt-6 inline-block rounded-lg px-3 pb-0.5 text-xl">
                {ticketDetails?.ticketName}
                {ticketDetails?.ticketExInfo
                  ? `: ${ticketDetails.ticketExInfo}`
                  : ""}
              </p>
              {ticketDetails?.ticketPoolName ? (
                <p className="mt-1 text-base text-sea-200">
                  {ticketDetails?.ticketPoolName}
                </p>
              ) : null}
              {ticketDetails?.ticketImportedForm ? <p className="mt-1 text-base text-sea-200 break-all w-full">
                Lista: {ticketDetails?.ticketImportedForm}
              </p> : null}
            </div>
          </div>
          <Modal.Actions
            className={classNames(
              "!mt-4",
              isInsideTheApp && isIOS ? "pb-16" : "pb-4",
            )}
          >
            <Button
              onClick={() => {
                onContinueScanning();
              }}
              size="small"
              type="button"
            >
              Skanuj dalej
            </Button>
            <Button
              onClick={() => setShouldDisplayErrorForm(true)}
              size="small"
              theme="dark"
              variant="empty"
              className="mt-2"
            >
              Podaj powód błędu
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};
