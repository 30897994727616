/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTicket = /* GraphQL */ `
  subscription OnCreateTicket($filter: ModelSubscriptionTicketFilterInput) {
    onCreateTicket(filter: $filter) {
      id
      eventId
      ticketId
      metadata
      validated
      active
      log
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTicket = /* GraphQL */ `
  subscription OnUpdateTicket($filter: ModelSubscriptionTicketFilterInput) {
    onUpdateTicket(filter: $filter) {
      id
      eventId
      ticketId
      metadata
      validated
      active
      log
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteTicket = /* GraphQL */ `
  subscription OnDeleteTicket($filter: ModelSubscriptionTicketFilterInput) {
    onDeleteTicket(filter: $filter) {
      id
      eventId
      ticketId
      metadata
      validated
      active
      log
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCommand = /* GraphQL */ `
  subscription OnCreateCommand($filter: ModelSubscriptionCommandFilterInput) {
    onCreateCommand(filter: $filter) {
      id
      scannerId
      type
      timestamp
      data
      response
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCommand = /* GraphQL */ `
  subscription OnUpdateCommand($filter: ModelSubscriptionCommandFilterInput) {
    onUpdateCommand(filter: $filter) {
      id
      scannerId
      type
      timestamp
      data
      response
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCommand = /* GraphQL */ `
  subscription OnDeleteCommand($filter: ModelSubscriptionCommandFilterInput) {
    onDeleteCommand(filter: $filter) {
      id
      scannerId
      type
      timestamp
      data
      response
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
