import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNetworkState, usePrevious, useTimeoutFn } from "react-use";

type ContextValues = {
  isOnline: boolean;
  isRestored: boolean;
};

const NetworkStatusContext = createContext<ContextValues>({
  isOnline: true,
  isRestored: false,
});

type Props = {
  children: ReactNode;
};

export const NetworkStatusProvider = ({ children }: Props) => {
  const networkState = useNetworkState();
  const prevNetworkState = usePrevious(networkState);
  const [isOnline, setIsOnline] = useState(true); // we use state instead directly accessing it on networkState to prevent layout shift between offline and restored UI state.
  const [isRestored, setIsRestored] = useState(false);

  const [, , reset] = useTimeoutFn(() => setIsRestored(false), 2_500);

  useEffect(() => {
    if (prevNetworkState && !prevNetworkState.online && networkState.online) {
      setIsRestored(true);
      reset();
    }
    setIsOnline(!!networkState.online);
  }, [prevNetworkState, networkState, reset]);

  return (
    <NetworkStatusContext.Provider
      value={{
        isOnline,
        isRestored,
      }}
    >
      {children}
    </NetworkStatusContext.Provider>
  );
};

export const useNetworkStatus = () => useContext(NetworkStatusContext);
