import { PhoneIcon } from "@heroicons/react/24/outline";
import { Button } from "ui/Button/Button";
import { Divider } from "ui/Divider/Divider";
import { Modal } from "ui/Modal/Modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export const HelpModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="text-center"
      isCloseIconVisible={false}
    >
      <div className="px-2">
        <Modal.Title>Numery kontaktowe</Modal.Title>
        <Modal.Message>
          Skontaktuj się z wybraną osobą poprzez kliknięcie w numer telefonu.
        </Modal.Message>
        <div className="mt-3 text-left">
          <div className="py-4">
            <a
              className="flex text-xl font-medium text-tixy-500"
              href="tel:509324293"
            >
              <PhoneIcon className="mr-2 w-5" /> 509-324-293
            </a>
            <p className="mt-2 font-medium">Joanna Kustrzyńska-Markiewicz</p>
            <p className="font-light text-stone-700">Event Manager</p>
          </div>
          <Divider />
          <div className="py-4">
            <a
              className="flex text-xl font-medium text-tixy-500"
              href="tel:509324293"
            >
              <PhoneIcon className="mr-2 w-5" /> 509-324-293
            </a>
            <p className="mt-2 font-medium">Joanna Kustrzyńska-Markiewicz</p>
            <p className="font-light text-stone-700">Dyrektor Ochrony</p>
          </div>
        </div>
        <Modal.Actions>
          <Button onClick={onClose} size="small">
            Anuluj
          </Button>
        </Modal.Actions>
      </div>
    </Modal>
  );
};
