import { CheckIcon } from "@heroicons/react/20/solid";
import { SignalSlashIcon } from "@heroicons/react/24/outline";
import { Alert } from "ui/Alert/Alert";
import { useNetworkStatus } from "./NetworkStatusProvider";

type Props = {
  className?: string;
};

export const NetworkStatus = ({ className }: Props) => {
  const { isOnline, isRestored } = useNetworkStatus();

  return (
    <>
      {!isOnline ? (
        <Alert className={className} variant="error" icon={SignalSlashIcon}>
          Brak dostępu do internetu
        </Alert>
      ) : isRestored ? (
        <Alert className={className} variant="success" icon={CheckIcon}>
          Odzyskałeś dostęp do internetu
        </Alert>
      ) : null}
    </>
  );
};
