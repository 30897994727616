import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as TixyLogo } from "assets/tixyLogo.svg";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import Countdown from "react-countdown";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { HelpModal } from "shared/HelpModal";
import { usePWAContext } from "shared/PWAContext/PWAContext";
import { useScannerEvent } from "shared/ScannerPagesWrapper/EventProvider";
import { useScannerConfig } from "shared/ScannerPagesWrapper/ScannerProvider";
import { Button } from "ui/Button/Button";
import { Loader } from "../../ui/Loader/Loader";

const EventDetails = () => {
  const { eventName, isAfterScanningTime, eventImage, eventStartDate, venue } =
    useScannerEvent();

  return (
    <div
      className={classNames(
        "mt-6 overflow-auto rounded-lgplus bg-[#110E2C]",
        isAfterScanningTime && "brightness-75",
      )}
    >
      {eventImage ? (
        <img
          src={eventImage}
          className={classNames(
            "h-36 w-full object-cover",
            isAfterScanningTime && "grayscale",
          )}
          alt=""
        />
      ) : (
        <div className="h-36 w-full object-cover" />
      )}

      <div className="p-4">
        <p className="text-2xl font-medium">{eventName}</p>
        <div className="mt-6 grid grid-cols-12 gap-2 text-sm">
          <div className="col-span-6 flex items-center rounded-lgplus bg-tixy-900 px-5 py-4">
            <CalendarIcon className="mr-2 h-5 w-5 text-tixy-300" />
            {eventStartDate ? format(eventStartDate, "dd/MM/yyyy") : undefined}
          </div>
          <div className="col-span-6 flex items-center rounded-lgplus bg-tixy-900 px-5 py-4">
            <ClockIcon className="mr-2 h-5 w-5 text-tixy-300" />
            {eventStartDate ? format(eventStartDate, "HH:mm") : undefined}
          </div>
          <div className="col-span-12 flex items-center rounded-lgplus bg-tixy-900 px-5 py-4">
            <MapPinIcon className="mr-2 h-5 w-5 text-tixy-300" />
            {venue}
          </div>
        </div>
      </div>
    </div>
  );
};

export const EventPage = () => {
  const { scannerToken, scanningName, scannerId } = useScannerConfig();
  const { openInstallationPopup, isInsideTheApp } = usePWAContext();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const isFromEventQRPage = !!searchParams.get("isFromEventQRPage");
  const {
    isLoading,
    isEventFetched,
    scanningStartDate,
    isAfterScanningTime,
    isBeforeScanningTime,
  } = useScannerEvent();

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  return (
    <>
      <TixyLogo className="m-auto" />
      <p className="mt-10 text-center text-2xl font-medium">
        Cześć {scanningName}!
      </p>
      {isEventFetched && !isLoading ? (
        <>
          {isAfterScanningTime ? (
            <>
              <div className="text-center">
                <p className="m-auto mt-4 inline-block rounded-lgplus bg-ruby-650 px-4 py-1 text-base">
                  Twój dostęp do wydarzenia wygasł
                </p>
              </div>
              <EventDetails />
              <Button
                className="mt-6 w-full"
                size="small"
                variant="empty"
                theme="dark"
                onClick={() => setIsHelpModalOpen(true)}
              >
                Pomoc
              </Button>
            </>
          ) : isBeforeScanningTime ? (
            <Countdown
              date={parseISO(scanningStartDate!)}
              renderer={({ completed, formatted, days }) => {
                if (completed) {
                  window.location.reload();
                }
                return (
                  <>
                    <div className="mt-4 flex items-center justify-between rounded-lgplus bg-tixy-400 px-4 py-2.5 text-base">
                      Skanowanie dostępne za:
                      <span className="text-right text-xl font-medium">
                        {days > 0 ? (
                          <span className="whitespace-nowrap">
                            {new Intl.RelativeTimeFormat("pl", {
                              style: "short",
                            }).format(days, "day")}
                            <br />
                          </span>
                        ) : null}
                        {formatted.hours}:{formatted.minutes}:
                        {formatted.seconds}
                      </span>
                    </div>
                    <EventDetails />
                    <Button
                      className="mt-6 w-full"
                      size="small"
                      variant="empty"
                      theme="dark"
                      onClick={() => setIsHelpModalOpen(true)}
                    >
                      Pomoc
                    </Button>
                  </>
                );
              }}
            />
          ) : (
            <>
              <p className="mt-2 text-center text-lg font-light leading-[28px] text-stone-500">
                Aplikacja jest gotowa do skanowania. Sprawdź dane poniżej, aby
                upewnić się, że otrzymałeś poprawny link.
              </p>
              <Link to={`/${scannerToken}/scanner?scanningMethod=redLight`}>
                <Button className="mb-3 mt-4 w-full" size="small">
                  Skanuj laserem
                </Button>
              </Link>
              <Link to={`/${scannerToken}/scanner?scanningMethod=camera`}>
                <Button className="mb-3 w-full" size="small">
                  Skanuj aparatem
                </Button>
              </Link>
              <Link to={`/${scannerToken}/stats`}>
                <Button
                  className="mb-3 w-full"
                  size="small"
                  variant="empty"
                  theme="dark"
                >
                  Statystyki
                </Button>
              </Link>
              {isFromEventQRPage ? (
                <Link to="/" state={{ previousLocation: location }}>
                  <Button
                    className="w-full"
                    size="small"
                    variant="empty"
                    theme="dark"
                  >
                    Zmień wydarzenie
                  </Button>
                </Link>
              ) : null}
              <EventDetails />
            </>
          )}
        </>
      ) : isLoading ? (
        <Loader className="my-4" />
      ) : null}
      <span className="text-sm brightness-75 my-2 text-center block">
        Skaner ID: {scannerId}
      </span>
      <div className="flex flex-col gap-y-4">
        {isInsideTheApp ? null : (
          <Button variant="text" size="small" onClick={openInstallationPopup}>
            Zainstaluj
          </Button>
        )}
      </div>
      <HelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
      />
    </>
  );
};
