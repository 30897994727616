import { XMarkIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { isIOS } from "react-device-detect";

const steps = isIOS
  ? [
      "Otwórz link w przeglądarce Safari",
      'Kliknij "Udostępnij" w pasku nawigacji',
      'Kliknij "Do ekranu początkowego"',
    ]
  : [
      "Otwórz link w przeglądarce Chrome",
      "Kliknij w trzy kropki w pasku nawigacji",
      'Kliknij "Dodaj do ekranu głównego"',
    ];

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const PWAInstallPopup = ({ isOpen, onClose }: Props) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="absolute left-0 top-0 z-50 flex h-screen w-full flex-col bg-tixy-1000 bg-opacity-75 text-tixy-1000">
      <div className="relative mt-auto rounded-t-lgplus bg-white px-4 pb-20 pt-6">
        <button
          onClick={onClose}
          className="absolute right-3 top-3 w-8 text-stone-600"
        >
          <XMarkIcon />
        </button>
        <div>
          <p className="text-2xl font-medium">Aplikacja skanująca TIXY</p>
          <p className="mt-4 font-light text-stone-800">
            Ta strona ma funkcje aplikacji, zapisz ją na ekranie początkowym, by
            mieć do niej szybki dostęp. Żeby to zrobić, postępuj zgodnie z
            instrukcją.
          </p>

          <div>
            <nav aria-label="Progress" className="mt-9">
              <ol>
                {steps.map((step, stepIdx) => (
                  <li
                    key={step}
                    className={classNames(
                      stepIdx !== steps.length - 1 ? "pb-10" : "",
                      "relative",
                    )}
                  >
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        className="absolute left-5 top-4 -ml-px mt-0.5 h-full w-0.5 bg-tixy-50"
                        aria-hidden="true"
                      />
                    ) : null}
                    <span className="group relative flex items-start">
                      <span
                        className="flex h-9 items-center"
                        aria-hidden="true"
                      >
                        <span className="relative z-10 flex h-10 w-10 items-center justify-center rounded-full bg-tixy-50 text-tixy-500">
                          {stepIdx + 1}
                        </span>
                      </span>
                      <span className="ml-4 flex min-w-0 flex-col">
                        <span className="text-xl text-tixy-500">{step}</span>
                      </span>
                    </span>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
