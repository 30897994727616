import { useEffect } from "react";
import { isIOS } from "react-device-detect";
import { useAudio } from "react-use";

export const useScannerSounds = () => {
  const [scanningFailureAudio, , failureAudioControls] = useAudio({
    src: process.env.PUBLIC_URL + "/fail-sound.mp3",
  });
  const [scanningSuccessAudio, , successAudioControls] = useAudio({
    src: process.env.PUBLIC_URL + "/success-sound.mp3",
  });

  useEffect(() => {
    if (!isIOS) {
      return;
    }

    // this should fix playing sounds on iOS: https://stackoverflow.com/questions/31776548/why-cant-javascript-play-audio-files-on-iphone-safari
    let playedOnce = false;
    const playSoundsSilently = () => {
      if (!playedOnce) {
        failureAudioControls.play();
        successAudioControls.play();
        setTimeout(() => {
          failureAudioControls.mute();
          successAudioControls.mute();
        }, 1);

        setTimeout(() => {
          failureAudioControls.unmute();
          successAudioControls.unmute();
        }, 500);
        playedOnce = true;
      }
    };
    document.addEventListener("touchstart", playSoundsSilently);
    return () => document.removeEventListener("touchstart", playSoundsSilently);
  }, []); // eslint-disable-line

  return {
    audioComponent: (
      <>
        {scanningFailureAudio}
        {scanningSuccessAudio}
      </>
    ),
    playFailureSound: () => failureAudioControls.play(),
    playSuccessSound: () => successAudioControls.play(),
  };
};
