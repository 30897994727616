import { Outlet } from "react-router-dom";
import { Alert } from "ui/Alert/Alert";
import { EventContextProvider } from "./EventProvider";
import { ScannerProvider, useScannerConfig } from "./ScannerProvider";
import { DataStoreSetupProvider } from "./DataStoreSetupProvider";

const Layout = () => {
  const { isScannerTokenValid } = useScannerConfig();

  return (
    <>
      {isScannerTokenValid ? (
        <EventContextProvider>
          <DataStoreSetupProvider>
            <Outlet />
          </DataStoreSetupProvider>
        </EventContextProvider>
      ) : (
        <Alert variant="error">
          Token skanera jest nieprawidłowy, upewnij się, że posiadasz poprawny
          link.
        </Alert>
      )}
    </>
  );
};
export const ScannerPagesWrapper = () => {
  return (
    <ScannerProvider>
      <Layout />
    </ScannerProvider>
  );
};
