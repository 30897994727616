import { generateClient } from "@aws-amplify/api";
import {
  GraphQLOptionsV6,
  GraphQLQuery,
  GraphQLSubscription,
  GraphQLResult,
} from "@aws-amplify/api-graphql";
import { SWRConfiguration } from "swr";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import useSWRSubscription from "swr/subscription";

const client = generateClient();

export const useQueryImmutable = <R extends {}, V extends {}>({
  skip,
  config,
  ...options
}: GraphQLOptionsV6<V> & {
  skip?: boolean;
  config?: SWRConfiguration;
}) => {
  return useSWRImmutable(
    skip ? null : [options.query, JSON.stringify(options.variables)],
    () =>
      client.graphql<GraphQLQuery<R>>(options) as unknown as GraphQLResult<
        GraphQLQuery<R>
      >,
    config,
  );
};

export const useMutation = <R extends {}, V extends {}>(
  options: GraphQLOptionsV6<V>,
) => {
  return useSWRMutation(
    [options.query, JSON.stringify(options.variables)],
    async (_, { arg }: { arg: Omit<GraphQLOptionsV6<V>, "query"> }) =>
      client.graphql<GraphQLQuery<R>>({
        ...options,
        ...arg,
      }) as unknown as GraphQLResult<GraphQLQuery<R>>,
  );
};

export const useSubscription = <R extends object, V extends object>({
  shouldDeduplicateIncomingValues = true,
  skip = false,
  ...options
}: GraphQLOptionsV6<V> & {
  /**
   * If socket returns an object that has the same values as te previous object,
   * useSWRSubscription will return a reference to the first object - most likely for the performance reasons (to avoid unnecessary re-renders).
   * This is usefull, however, in some cases we want to be aware whenever new data from subscriptions comes in even if they're exactly the same as the previous ones.
   */
  shouldDeduplicateIncomingValues?: boolean;
  skip?: boolean;
}) => {
  return useSWRSubscription<GraphQLResult<GraphQLQuery<R>>>(
    skip ? null : [options.query, JSON.stringify(options.variables)],
    (_: any, { next }: any) => {
      const api = client.graphql<GraphQLSubscription<R>>({
        authMode: "apiKey",
        ...options,
      });
      const subscription = (api as any).subscribe({
        next: ({ data }: { data: any }) => {
          return next(
            null,
            shouldDeduplicateIncomingValues
              ? { data }
              : { __key: Date.now(), data },
          );
        },
        error: (e: any) => next(e),
      });

      return () => subscription.unsubscribe();
    },
  );
};
