import jsrsasign from "jsrsasign";

export const validateToken = (token: string, publicKey: string) => {
  // TODO This causes 3s delay on Zebra device TC26
  // const pubkey = jsrsasign.KEYUTIL.getKey(publicKey) as RSAKey;
  // return jsrsasign.KJUR.jws.JWS.verifyJWT(token, pubkey, {
  //   alg: ["ES256"],
  // });
  return true;
};

export const parseToken = <V extends {} = {}>(token: string): V =>
  jsrsasign.KJUR.jws.JWS.parse(token) as unknown as V;

export const getHashedTicket = (realTicketId: string, publicKey: string) => {
  const mac = new jsrsasign.KJUR.crypto.Mac({
    alg: "hmacmd5",
    pass: publicKey as any,
  });
  mac.updateString(realTicketId);
  return mac.doFinal();
};
