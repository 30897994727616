import { EventPage } from "pages/EventPage/page";
import { EventQRPage } from "pages/EventQRPage/page";
import { ScannerPage } from "pages/Scanner/page";
import { StatsPage } from "pages/StatsPage/page";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { PWAContextProvider } from "shared/PWAContext/PWAContext";
import { ScannerPagesWrapper } from "shared/ScannerPagesWrapper/ScannerPagesWrapper";
import { Toaster } from "ui/Toaster/Toaster";
import { NetworkStatus } from "shared/NetworkStatus/NetworkStatus";
import { NetworkStatusProvider } from "../shared/NetworkStatus/NetworkStatusProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <EventQRPage />,
  },
  {
    path: "/:scannerToken",
    element: <ScannerPagesWrapper />,
    children: [
      {
        path: "/:scannerToken",
        element: <EventPage />,
      },
      {
        path: "/:scannerToken/scanner",
        element: <ScannerPage />,
      },
      {
        path: "/:scannerToken/stats",
        element: <StatsPage />,
      },
    ],
  },
]);

export const App = () => {
  return (
    <div className="min-h-screen bg-tixy-1000 px-4 pb-6 pt-4 text-white">
      <NetworkStatusProvider>
        <NetworkStatus className="mb-2 z-10 relative" />
        <PWAContextProvider>
          <RouterProvider router={router} />
        </PWAContextProvider>
        <Toaster position="top-center" />
      </NetworkStatusProvider>
    </div>
  );
};
