import { Dialog, DialogProps, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CheckmarkInCircleIcon } from "assets/icons/CheckmarkInCircleIcon";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import { CircledIcon } from "./CircledIcon";

export type Props = DialogProps<"div"> & {
  className?: string;
  maxWidth?: string;
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  isCloseIconVisible?: boolean;
  afterIsClosed?: () => void; // usefull to cleanup the state when modal closes
  background?: string;
  noPadding?: boolean;
};

export const Modal = ({
  className,
  children,
  isOpen,
  onClose = () => null,
  isCloseIconVisible = true,
  maxWidth = "sm:max-w-sm",
  afterIsClosed,
  noPadding = false,
  background = "bg-white",
  ...dialogProps
}: Props) => {
  return (
    <Transition show={isOpen} as={Fragment} afterLeave={afterIsClosed}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={onClose}
        {...dialogProps}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-tixy-1000 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "relative w-full transform overflow-hidden rounded-lg text-left shadow-xl transition-all",
                  noPadding ? "" : "px-4 py-4",
                  maxWidth,
                  background,
                  className,
                )}
              >
                {isCloseIconVisible ? (
                  <div className="absolute right-0 top-0 hidden pr-3.5 pt-3.5 sm:block">
                    <button
                      type="button"
                      className="rounded-lgplus bg-white text-stone-600 hover:text-stone-700 focus:outline-none"
                      onClick={onClose}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                ) : null}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const SuccessIcon = () => {
  return (
    <div className="mb-8 text-center">
      <CircledIcon iconVariant="sea">
        <CheckmarkInCircleIcon />
      </CircledIcon>
    </div>
  );
};

const ErrorIcon = () => {
  return (
    <div className="mb-8 text-center">
      <CircledIcon iconVariant="ruby">
        <ExclamationCircleIcon />
      </CircledIcon>
    </div>
  );
};

const InfoIcon = () => {
  return (
    <div className="mb-8 text-center">
      <CircledIcon iconVariant="stone">
        <InformationCircleIcon />
      </CircledIcon>
    </div>
  );
};

const Title = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <h4
      className={classNames(
        "mb-3 text-2xl font-bold md:mb-4 md:text-3xlplus",
        className,
      )}
    >
      {children}
    </h4>
  );
};

const Message = ({ children }: { children: ReactNode }) => {
  return <p className="text-lg font-normal text-stone-800">{children}</p>;
};

const Actions = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames("mt-6 flex flex-col gap-y-2 md:mt-8", className)}
    >
      {children}
    </div>
  );
};

Modal.SuccessIcon = SuccessIcon;
Modal.ErrorIcon = ErrorIcon;
Modal.InfoIcon = InfoIcon;
Modal.Title = Title;
Modal.Message = Message;
Modal.Actions = Actions;
