import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { useMedia, useSessionStorage } from "react-use";
import { PWAInstallPopup } from "./PWAInstallPopup";

type ContextValues = {
  openInstallationPopup: () => void;
  isInsideTheApp: boolean;
};

const PWAContext = createContext<ContextValues>({
  openInstallationPopup: () => null,
  isInsideTheApp: false,
});

type Props = {
  children: ReactNode;
};

export const PWAContextProvider = ({ children }: Props) => {
  const [hasPWAPopupBeenDisplayed, setHasPWAPopupBeenDisplayed] =
    useSessionStorage<boolean>("has-pwa-popup-been-displayed");
  // use to check if inside desktop app
  const isStandalone = useMedia("(display-mode: standalone)");
  const [isVisible, setIsVisible] = useState(
    !hasPWAPopupBeenDisplayed && !isStandalone,
  );

  const closeInstallationPopup = () => {
    setHasPWAPopupBeenDisplayed(true);
    setIsVisible(false);
  };

  const values: ContextValues = useMemo(
    () => ({
      openInstallationPopup: () => setIsVisible(true),
      isInsideTheApp: isStandalone,
    }),
    [isStandalone],
  );

  return (
    <PWAContext.Provider value={values}>
      {children}
      <PWAInstallPopup isOpen={isVisible} onClose={closeInstallationPopup} />
    </PWAContext.Provider>
  );
};

export const usePWAContext = () => useContext(PWAContext);
