import classNames from "classnames";

type Props = {
  className?: string;
  label?: string;
};
export const Divider = ({ className, label }: Props) => {
  return (
    <div className={classNames("relative h-[1px]", className)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-stone-300" />
      </div>
      {label ? (
        <div className="relative flex -translate-y-1/2 justify-center">
          <span className="bg-white px-2 text-sm text-stone-700">{label}</span>
        </div>
      ) : null}
    </div>
  );
};
