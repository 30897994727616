import { SVGProps } from "react";

export const CheckmarkInCircleIcon = (
  props: Omit<SVGProps<SVGElement>, "ref">,
) => {
  return (
    <svg
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42 22.3543V24.0103C41.9978 27.8919 40.7409 31.6687 38.4168 34.7776C36.0927 37.8864 32.8259 40.1608 29.1036 41.2613C25.3814 42.3619 21.4031 42.2297 17.762 40.8845C14.121 39.5394 11.0124 37.0533 8.89973 33.797C6.78708 30.5408 5.78362 26.6888 6.03902 22.8157C6.29441 18.9425 7.79497 15.2557 10.3169 12.305C12.8388 9.35438 16.247 7.29799 20.0331 6.44257C23.8193 5.58714 27.7805 5.97851 31.326 7.5583"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 9L24.3077 28L19 22.3057"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
