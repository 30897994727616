import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import hotToast, {
  Toaster as HTToaster,
  Toast,
  ToastOptions,
} from "react-hot-toast";

type ToastMessageProps = {
  t: Toast;
  message: ReactNode;
  variant: "success" | "error" | "info";
};
const ToastMessage = ({ t, message, variant }: ToastMessageProps) => {
  return (
    <Transition
      show={t.visible}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={classNames(
          "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-opacity-5",
          variant === "success"
            ? "bg-tixy-800 text-white ring-tixy-800"
            : variant === "info"
            ? "bg-sand-200 text-sand-600 ring-sand-600"
            : "bg-ruby-650 text-white ring-ruby-650",
        )}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="mt-0.5 flex-shrink-0">
              {variant === "success" ? (
                <CheckCircleIcon className="h-6 w-6" aria-hidden="true" />
              ) : (
                <ExclamationTriangleIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-base font-medium">{message}</p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className={classNames(
                  "inline-flex rounded-lgplus ring-offset-transparent focus:outline-none focus:ring-1 focus:ring-offset-1",
                  variant === "success"
                    ? "hover:text-tixy-600 focus:ring-tixy-500"
                    : variant === "info"
                    ? "hover:text-sand-600 focus:ring-sand-600"
                    : "hover:text-stone-100 focus:ring-white",
                )}
                onClick={() => hotToast.dismiss(t.id)}
              >
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export const Toaster = HTToaster;

export const toast = {
  success: (message: string, options?: ToastOptions) => {
    hotToast.custom(
      (t) => <ToastMessage t={t} message={message} variant="success" />,
      { duration: 2000, ...options },
    );
  },
  info: (message: string, options?: ToastOptions) => {
    hotToast.custom(
      (t) => <ToastMessage t={t} message={message} variant="info" />,
      { duration: 4000, ...options },
    );
  },
  error: (message: string, options?: ToastOptions) => {
    hotToast.custom(
      (t) => <ToastMessage t={t} message={message} variant="error" />,
      { duration: 4000, ...options },
    );
  },
};
