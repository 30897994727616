// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CommandType = {
  "GET_CONFIG": "GetConfig",
  "SET_CONFIG": "SetConfig",
  "GET_ERRORS": "GetErrors",
  "GET_SCANNER_INFO": "GetScannerInfo",
  "GET_LOCAL_DATA_AMOUNT": "GetLocalDataAmount",
  "RESET_SCANNER": "ResetScanner",
  "RECONNECT": "Reconnect",
  "DISABLE_SCANNER": "DisableScanner"
};

const { Ticket, Command, SalesDates, TicketPoolTemplate, TicketTemplate, TicketsTemplate, ScannerSettingsTemplate, EventResponse, Artist, Venue, ValidateTicketResponse } = initSchema(schema);

export {
  Ticket,
  Command,
  CommandType,
  SalesDates,
  TicketPoolTemplate,
  TicketTemplate,
  TicketsTemplate,
  ScannerSettingsTemplate,
  EventResponse,
  Artist,
  Venue,
  ValidateTicketResponse
};