import classNames from "classnames";
import { cloneElement } from "react";

type Props = {
  className?: string;
  children: JSX.Element;
  iconVariant?: "tixy" | "ruby" | "stone" | "sea";
};

export const CircledIcon = ({ className, children, iconVariant }: Props) => (
  <span
    className={classNames(
      "inline-flex h-[72px] w-[72px] rounded-full p-3 shadow-md",
      iconVariant === "tixy"
        ? "bg-tixy-900 text-tixy-400"
        : iconVariant === "stone"
          ? "bg-stone-500 text-white"
          : iconVariant === "ruby"
            ? "bg-ruby-650 text-white"
            : iconVariant === "sea"
              ? "bg-white text-sea-450"
              : "",
      className,
    )}
  >
    {cloneElement(children, { style: { width: "100%", height: "100%" } })}
  </span>
);
