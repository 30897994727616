import * as deviceInfo from "react-device-detect";

export const getPercentage = (val: number, divider: number) => {
  if (divider === 0) {
    // cant divide by 0
    return 0;
  }

  return Math.round((val / divider) * 100);
};

export const wait = (delay: number) =>
  new Promise((res) => setTimeout(res, delay));

const isStorageAvailable = typeof localStorage !== "undefined";

export const getItem = (itemName: string): string => {
  return (isStorageAvailable && window.localStorage.getItem(itemName)) || "";
};

export const setItem = (itemName: string, data: string): void => {
  if (isStorageAvailable) {
    window.localStorage.setItem(itemName, data);
  }
};

export const removeItem = (itemName: string): void => {
  if (isStorageAvailable) {
    window.localStorage.removeItem(itemName);
  }
};

export const logConnectionType = () => {
  let connectionType = "not supported";
  let downlinkMax = "not supported";

  if ("connection" in navigator) {
    // @ts-ignore
    connectionType = navigator.connection?.effectiveType;

    // @ts-ignore
    if ("downlinkMax" in navigator.connection) {
      // @ts-ignore
      downlinkMax = navigator.connection?.downlinkMax;
    }
  }

  return {
    connectionType,
    downlinkMax,
  }
}

export const omit = <T extends object>(obj: T, keys: (keyof T)[]) => {
  return Object.fromEntries(
    // @ts-ignore
    Object.entries(obj).filter(([key]) => !keys.includes(key)),
  );
};

export const getDeviceInfo = () => {
  return omit(deviceInfo, [
    "AndroidView",
    "BrowserTypes",
    "BrowserView",
    "ConsoleView",
    "CustomView",
    "IEView",
    "IOSView",
    "MobileOnlyView",
    "MobileView",
    "OsTypes",
    "SmartTVView",
    "TabletView",
    "WearableView",
    "WinPhoneView",
    "deviceDetect",
    "getSelectorsByUserAgent",
    "parseUserAgent",
    "setUserAgent",
    "useDeviceData",
    "useDeviceSelectors",
    "useMobileOrientation",
    "withOrientationChange",
  ]);
};
