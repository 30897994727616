import classNames from "classnames";
import { FieldHookConfig, useField } from "formik";
import { InputHTMLAttributes, ReactNode, useId } from "react";

type Props = InputHTMLAttributes<HTMLTextAreaElement> & {
  label: ReactNode;
  type?: string;
  className?: string;
  error?: string;
  helperText?: string;
  dataTestId?: string;
};

export const Textarea = ({
  label,
  required,
  className,
  value = "",
  error,
  helperText,
  disabled,
  dataTestId,
  ...textareaElementProps
}: Props) => {
  const id = useId();
  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-normal text-stone-900">
        {label}
        {required ? "*" : ""}
      </label>
      <div className="mt-1">
        <textarea
          rows={4}
          id={id}
          style={{ boxShadow: "none" }} // replace with tailwing, focus:shadow-none doesnt work
          className={classNames(
            "block w-full rounded-lgplus px-3 py-2.5 shadow-none focus:shadow-none sm:text-sm",
            disabled
              ? "border-stone-400 text-stone-500 placeholder-stone-500"
              : error
              ? "border-ruby-600 text-ruby-800 placeholder-ruby-600 focus:border-ruby-600 focus:outline-none focus:ring-ruby-600"
              : "border-stone-600 placeholder-stone-700 focus:border-tixy-400 focus:ring-tixy-400",
          )}
          required={required}
          value={value}
          disabled={disabled}
          data-testid={dataTestId}
          {...textareaElementProps}
        />
      </div>
      {error ? <p className="mt-1 text-xs text-ruby-800">{error}</p> : null}
      {!error && helperText ? (
        <p className="mt-1 text-xs text-stone-700">{helperText}</p>
      ) : null}
    </div>
  );
};

type ConnectedTextareaProps = Omit<Props, "onChange" | "value"> &
  Pick<FieldHookConfig<string>, "name" | "validate">;
export const ConnectedTextarea = (props: ConnectedTextareaProps) => {
  const [field, meta] = useField<string>(props);
  return (
    <Textarea
      {...field}
      {...props}
      error={meta.error && meta.touched ? meta.error : undefined}
    />
  );
};
