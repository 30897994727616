import { Amplify } from "aws-amplify";
import {
  DataStore,
  syncExpression,
  DataStoreConfig,
} from "aws-amplify/datastore";
import { Command, Ticket } from "models";
import { awsExportsConfig } from "shared/envVariables";

type Config = Pick<
  DataStoreConfig,
  "syncPageSize" | "maxRecordsToSync" | "fullSyncInterval"
> & {
  authToken: string;
  eventId: string;
  scannerId: string;
  onError: DataStoreConfig["errorHandler"];
};

export const configure = ({
  authToken,
  eventId,
  scannerId,
  onError,
  ...rest
}: Config) => {
  Amplify.configure(JSON.parse(awsExportsConfig));
  DataStore.configure({
    syncPageSize: 5_000,
    maxRecordsToSync: 50_000,
    fullSyncInterval: 60 * 48, // 2 days in minutes
    ...rest,
    authProviders: {
      functionAuthProvider: () => ({
        token: authToken,
      }),
    },
    syncExpressions: [
      syncExpression(Ticket, () => (ticket) => ticket.eventId.eq(eventId)),
      syncExpression(Command, () => {
        return (command) => command.scannerId.eq(scannerId);
      }),
    ],
    errorHandler: onError,
  });
};
