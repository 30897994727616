import { Formik } from "formik";
import { Button } from "ui/Button/Button";
import { ConnectedInput } from "ui/Input/Input";
import { Modal } from "ui/Modal/Modal";
import * as Yup from "yup";

export type SubmitHandler = (values: FormValues) => void;

type FormValues = {
  ticketId: string;
};

type Props = {
  isOpen: boolean;
  isCheckingTicketOnline: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
};
export const EnterTicketIdManuallyModal = ({
  isOpen,
  isCheckingTicketOnline,
  onClose,
  onSubmit,
}: Props) => {
  const validationSchema: Yup.Schema<FormValues> = Yup.object().shape({
    ticketId: Yup.string().required("Podaj kod"),
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCloseIconVisible={false}>
      <div className="px-2">
        <Modal.Title className="mb-5 text-center">Wpisz kod biletu</Modal.Title>

        <Formik<FormValues>
          initialValues={{ ticketId: "" }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <ConnectedInput
                name="ticketId"
                placeholder="XXXXXXXX"
                label="Kod biletu"
                autoComplete="off"
              />
              <Modal.Actions>
                <Button
                  size="small"
                  type="submit"
                  disabled={isCheckingTicketOnline}
                  isLoading={isCheckingTicketOnline}
                >
                  Sprawdź
                </Button>
                <Button
                  onClick={onClose}
                  size="small"
                  variant="empty"
                  type="button"
                  disabled={isCheckingTicketOnline}
                  isLoading={isCheckingTicketOnline}
                >
                  Anuluj
                </Button>
              </Modal.Actions>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
