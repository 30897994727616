import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { FieldHookConfig, useField } from "formik";
import { FocusEventHandler, ReactNode } from "react";

export type Option = {
  id: string;
  label: ReactNode;
  isEnabled?: boolean;
};

type Props = {
  className?: string;
  wrapperClassName?: string;
  label?: ReactNode;
  value: Option | null;
  name: string;
  onChange: (option: Option | null) => void;
  options: Option[];
  onBlur?: FocusEventHandler<HTMLDivElement>;
  error?: string;
  disabled?: boolean;
  dataTestId?: string;
};

export const RadioButtonGroup = ({
  className,
  name,
  label,
  value,
  onChange,
  options,
  onBlur,
  error,
  disabled,
  wrapperClassName = "grid-cols-3 sm:grid-cols-4",
  dataTestId,
}: Props) => {
  return (
    <div className={className}>
      {label ? (
        <label className="text-sm font-normal text-stone-900">{label}</label>
      ) : null}
      <RadioGroup
        className="mt-2"
        value={value}
        onChange={onChange}
        name={name}
        onBlur={onBlur}
        disabled={disabled}
        data-testid={dataTestId}
      >
        <div className={classNames("grid gap-3", wrapperClassName)}>
          {options.map((option) => (
            <RadioGroup.Option
              key={option.id}
              value={option}
              className={({ checked }) =>
                classNames(
                  "z-10 rounded-lgplus",
                  checked ? "border-tixy-400 bg-tixy-10" : "border-stone-600",
                  "relative flex cursor-pointer flex-col border p-4 focus:outline-none md:px-4 md:py-3",
                  disabled && "cursor-auto border-stone-400",
                )
              }
            >
              {({ checked }) => (
                <>
                  <span className="flex items-center text-sm">
                    <span
                      className={classNames(
                        checked
                          ? "border-transparent bg-tixy-500"
                          : "border-stone-400 bg-white",
                        "flex h-4 w-4 items-center justify-center rounded-full border",
                      )}
                      aria-hidden="true"
                    >
                      <span className="h-1.5 w-1.5 rounded-full bg-white" />
                    </span>
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        disabled
                          ? "text-stone-400"
                          : checked
                          ? "text-tixy-600"
                          : "text-stone-700",
                        "ml-3",
                      )}
                    >
                      {option.label}
                    </RadioGroup.Label>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {error ? <p className="mt-1 text-xs text-ruby-800">{error}</p> : null}
    </div>
  );
};

type ConnectedRadioButtonGroupProps = Omit<Props, "onChange" | "value"> &
  Pick<FieldHookConfig<string>, "name" | "validate">;
export const ConnectedRadioButtonGroup = (
  props: ConnectedRadioButtonGroupProps,
) => {
  const [field, , helpers] = useField<string>(props);

  return (
    <RadioButtonGroup
      {...field}
      {...props}
      value={props.options.find(({ id }) => id === field.value) || null}
      onChange={(item) => helpers.setValue(item?.id || "")}
      onBlur={() => helpers.setTouched(true)}
    />
  );
};
