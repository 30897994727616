import { SpinnerIcon } from "assets/icons/SpinnerIcon";
import classNames from "classnames";
import { ButtonHTMLAttributes, FC, ReactNode } from "react";

type Variant = "primary" | "empty" | "text" | "danger";
type Theme = "light" | "dark";
type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  variant?: Variant;
  iconLeft?: FC<{ className?: string }>;
  iconRight?: FC<{ className?: string }>;
  isLoading?: boolean;
  hasPadding?: boolean;
  theme?: Theme;
  size?: "normal" | "small";
  dataTestId?: string;
};

export const Button = ({
  children,
  className,
  variant = "primary",
  iconLeft: IconLeft = () => null,
  iconRight: IconRight = () => null,
  isLoading = false,
  disabled,
  onClick,
  hasPadding = true,
  theme = "light",
  size = "normal",
  dataTestId,
  ...buttonElementProps
}: Props) => {
  const styles = (() => {
    const baseStyles: Record<Variant, string> = {
      primary:
        "box-border inline-flex items-center justify-center rounded-lgplus border-2 font-medium shadow-sm",
      empty:
        "box-border inline-flex items-center justify-center rounded-lgplus border-2 font-medium shadow-sm",
      text: "inline-flex items-center justify-center font-medium border-2",
      danger: "inline-flex items-center justify-center rounded-lgplus font-medium border-2 border-ruby-700",
    };
    const focus: Record<Variant, string> = {
      primary:
        "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tixy-400",
      empty:
        "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tixy-400",
      text: "",
      danger: "",
    };
    const basicColors: Record<Variant, string> = {
      primary: "bg-tixy-500 border-transparent text-white",
      empty: classNames(
        "border-tixy-500",
        theme === "light" ? "text-tixy-1000" : "text-white",
      ),
      text: "text-tixy-500 border-transparent",
      danger: "text-ruby-700",
    };
    const hoverColors: Record<Variant, string> = {
      primary: "hover:bg-tixy-600",
      empty: "hover:text-tixy-500",
      text: "hover:text-tixy-600",
      danger: "hover:text-ruby-650",
    };
    const activeColors: Record<Variant, string> = {
      primary: "active:bg-tixy-1000",
      empty: "active:border-tixy-600 active:text-tixy-600",
      text: "active:text-tixy-900",
      danger: "active:text-ruby-650",
    };
    const disabledColors: Record<Variant, string> = {
      primary: classNames(
        "border-transparent text-white",
        theme === "light" ? "bg-stone-400" : "bg-stone-800",
      ),
      empty:
        theme === "light"
          ? "border-stone-400 text-stone-400"
          : "border-stone-800 text-stone-800",
      text: classNames(
        "border-transparent",
        theme === "light" ? "text-stone-400" : "text-stone-800",
      ),
      danger: classNames(
        "!border-stone-800",
        "text-stone-800",
      ),
    };
    const padding = size === "normal" ? "py-3 px-6" : "py-2.5 px-4";
    const font = size === "normal" ? "text-lg" : "text-base";
    return classNames(
      font,
      baseStyles[variant],
      focus[variant],
      hasPadding && padding,
      !disabled && hoverColors[variant],
      !isLoading && !disabled && activeColors[variant],
      disabled ? disabledColors[variant] : basicColors[variant],
    );
  })();

  return (
    <button
      className={classNames(styles, className)}
      disabled={isLoading || disabled}
      onClick={isLoading ? undefined : onClick}
      data-testid={dataTestId}
      {...buttonElementProps}
    >
      <IconLeft
        className={classNames(
          "h-5 w-5",
          variant !== "text" ? "-ml-1 mr-3" : "-ml-1 mr-1",
        )}
      />
      <span className={classNames(isLoading && "text-transparent")}>
        {children}
      </span>
      {isLoading ? (
        <SpinnerIcon className="absolute h-5 w-5 animate-spin" />
      ) : null}
      <IconRight
        className={classNames(
          "h-5 w-5",
          variant !== "text" ? "-mr-1 ml-3" : "-mr-1 ml-1",
        )}
      />
    </button>
  );
};
