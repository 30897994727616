import { SVGProps } from "react";

export const SpinnerIcon = (props: Omit<SVGProps<SVGElement>, "ref">) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 1.5V4M8.5 14V16.5M16 9H13.5M3.5 9H1M13.8033 14.3033L12.2501 12.7501M4.75 5.25L3.1967 3.6967M13.8033 3.69674L12.2501 5.25M4.75 12.7501L3.19674 14.3033"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
