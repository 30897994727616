import classNames from "classnames";
import { SpinnerIcon } from "../../assets/icons/SpinnerIcon";

type Props = {
  className?: string;
  color?: string;
  spinnerClassName?: string;
};

export const Loader = ({
  className,
  color = "text-white",
  spinnerClassName,
}: Props) => {
  return (
    <div className={classNames("flex items-center justify-center", className)}>
      <SpinnerIcon
        className={classNames("h-7 w-7 animate-spin", color, spinnerClassName)}
      />
    </div>
  );
};
