import { useEffect, useMemo, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getHashedTicket, parseToken, validateToken } from "shared/token";
import { ParsedTicket, ScanningResult, TicketTokenData } from "shared/types";
import { HelpModal } from "shared/HelpModal";
import { useScannerEvent } from "shared/ScannerPagesWrapper/EventProvider";
import { Button } from "ui/Button/Button";
import {
  SubmitHandler as EnterCodeManuallyHandler,
  EnterTicketIdManuallyModal,
} from "./EnterTicketIdManuallyModal";
import { FailureModal } from "./FailureModal";
import { QRCameraReader } from "./QRCameraReader";
import { QRRedLightReader } from "./QRRedLightReader";
import { SuccessModal } from "./SuccessModal";
import { FailureLogDetails, useLogger } from "./useLogger";
import { useScannerSounds } from "./useScannerSounds";
import { ScanningStatus, useTicketValidator } from "./useTicketValidator";
import { useDataStoreSetup } from "../../shared/ScannerPagesWrapper/DataStoreSetupProvider";
import { useScannerConfig } from "../../shared/ScannerPagesWrapper/ScannerProvider";

export const ScannerPage = () => {
  const { scannerToken } = useParams<{
    scannerToken: string;
  }>();
  const [urlSearchParams] = useSearchParams();
  const scanningMethod = urlSearchParams.get("scanningMethod") || "camera";
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isEnterCodeManuallyModalOpen, setIsEnterCodeManuallyModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const log = useLogger();
  const [scanningStatus, setScanningStatus] = useState<
    ScanningStatus & { code?: string }
  >();
  const {
    tickets,
    isEventFetched,
    eventPublicKey,
    eventId,
    isBeforeScanningTime,
    isAfterScanningTime,
  } = useScannerEvent();
  const { isInitialized } = useDataStoreSetup();
  const { isScannerBlocked } = useScannerConfig();
  const { validateTicket, isCheckingTicketOnline } = useTicketValidator(isInitialized);
  const isFailureModalOpen =
    !!scanningStatus &&
    scanningStatus.status !== ScanningResult.TicketScannedSuccessfully;
  const isSuccessModalOpen =
    !!scanningStatus &&
    scanningStatus.status === ScanningResult.TicketScannedSuccessfully;
  const { audioComponent, playFailureSound, playSuccessSound } =
    useScannerSounds();
  const isScanning = useRef(false);
  isScanning.current =
    isEventFetched &&
    !isSuccessModalOpen &&
    !isFailureModalOpen &&
    !isHelpModalOpen &&
    !isEnterCodeManuallyModalOpen;

  const ticketDetails = useMemo(() => {
    const currentTicket = scanningStatus?.ticket as ParsedTicket | undefined;
    const ticketDefinition = tickets.find(
      (ticket) =>
        ticket.id === currentTicket?.ticketId ||
        ticket?.ticketPools?.some(
          (pool) => pool?.id === currentTicket?.ticketId,
        ),
    );
    const importLog = currentTicket?.log?.find(
      (item) =>
        item.type === "ticket-imported" ||
        item.type === "invitation-imported" ||
        item.type === "invitation-created",
    );

    return {
      ticketExInfo: importLog?.exInfo,
      ticketImportedForm: importLog?.from,
      code: scanningStatus?.code,
      ticketName: ticketDefinition?.name
        ? ticketDefinition.name
        : currentTicket?.ticketId === "inv" ||
            currentTicket?.ticketId === "invitation"
          ? "Zaproszenie"
          : "TIX",
      ticketPoolName:
        ticketDefinition?.ticketPools?.find(
          (pool) => pool?.id === currentTicket?.ticketId,
        )?.name || "",
    };
  }, [scanningStatus, tickets]);

  useEffect(() => {
    if (isBeforeScanningTime || isAfterScanningTime) {
      navigate(`/${scannerToken}`, { replace: true });
    }
  }, [navigate, scannerToken, isBeforeScanningTime, isAfterScanningTime]);

  useEffect(() => {
    if (isFailureModalOpen) {
      playFailureSound();
    }
    if (isSuccessModalOpen) {
      playSuccessSound();
    }
  }, [isFailureModalOpen, isSuccessModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleQRCodeRead = async (code: string) => {
    if (!isScanning.current || isScannerBlocked) {
      return;
    }

    let realTicketId = code;
    let tixId = code;
    if (code.length >= 159) {
      // TIXY QR code
      if (!validateToken(code, eventPublicKey)) {
        console.error("Invalid QR code:", code);
        setScanningStatus({
          status: ScanningResult.InvalidQRCode,
          invalidQrCode: code,
        });
        return;
      }

      try {
        const parsedData = parseToken<TicketTokenData>(code);
        realTicketId = parsedData.payloadObj.id;
        tixId = parsedData.payloadObj.id.split(":")?.[1];
      } catch (e) {
        console.error("Invalid QR code:", code);
        setScanningStatus({
          status: ScanningResult.InvalidQRCode,
          invalidQrCode: code,
          code,
        });
        return;
      }
    } else {
      // Other QR code
      console.log("non-tixy ticket");
      realTicketId = code;
      tixId = code;
    }

    const hashedTicketId = getHashedTicket(realTicketId, eventPublicKey);
    const status = await validateTicket(hashedTicketId, realTicketId);
    setScanningStatus({ ...(status as ScanningStatus), code: tixId });
  };

  const handleCodeEnteredManually: EnterCodeManuallyHandler = async (
    values,
  ) => {
    try {
      if (isScannerBlocked) {
        return;
      }
      // user will only see the short version of the ticket ID (without event id)
      const realTicketId = `${eventId.split("-")[0]}-${values.ticketId}`;
      const hashedTicketId = getHashedTicket(realTicketId, eventPublicKey);
      let status = await validateTicket(hashedTicketId, realTicketId);

      if (status?.status === ScanningResult.NonExistingTicket) {
        console.log(
          "Ticket does not exists. Checking if it's non-TIXY ticket...",
        );
        const hashedTicketId = getHashedTicket(values.ticketId, eventPublicKey);
        status = await validateTicket(hashedTicketId, values.ticketId);
      }

      setScanningStatus(status);
      setIsEnterCodeManuallyModalOpen(false);
    } catch (error) {
      console.log(error);
      console.error("Invalid ticket id:", values.ticketId);
      setScanningStatus({
        status: ScanningResult.InvalidTicketId,
        ticketId: values.ticketId,
        code: values.ticketId,
      });
      setIsEnterCodeManuallyModalOpen(false);
    }
  };

  const handleContinueScanning = (incidentDetails?: FailureLogDetails) => {
    if (!scanningStatus) {
      return;
    }
    log.logger(scanningStatus, incidentDetails);
    setScanningStatus(undefined);
    setIsEnterCodeManuallyModalOpen(false); // in case someone typed in ticket
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <Link to={`/${scannerToken}`} className="m-auto">
          <Button variant="text" size="small">
            Strona Główna
          </Button>
        </Link>
        <Button
          size="small"
          variant="empty"
          theme="dark"
          onClick={() => setIsHelpModalOpen(true)}
        >
          Pomoc
        </Button>
        <Button
          size="small"
          onClick={() => setIsEnterCodeManuallyModalOpen(true)}
        >
          Wpisz kod ręcznie
        </Button>
      </div>

      <div className="mt-10">
        {isEventFetched ? (
          // QRReader is caching the onRead function on the initial render, we must wait for event to make sure getHashedTicketId will work
          scanningMethod === "camera" ? (
            <QRCameraReader
              onRead={handleQRCodeRead}
              isCheckingTicketOnline={isCheckingTicketOnline}
            />
          ) : (
            <QRRedLightReader
              onRead={handleQRCodeRead}
              isCheckingTicketOnline={isCheckingTicketOnline}
            />
          )
        ) : null}
      </div>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        ticketDetails={ticketDetails}
        onClose={handleContinueScanning}
      />
      {scanningStatus ? (
        <FailureModal
          isOpen={isFailureModalOpen}
          errorType={scanningStatus?.status!}
          ticket={scanningStatus.ticket}
          onHelp={() => setIsHelpModalOpen(true)}
          onContinueScanning={handleContinueScanning}
          ticketDetails={ticketDetails}
        />
      ) : null}
      <HelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
      />
      <EnterTicketIdManuallyModal
        isOpen={isEnterCodeManuallyModalOpen}
        onClose={() => setIsEnterCodeManuallyModalOpen(false)}
        onSubmit={handleCodeEnteredManually}
        isCheckingTicketOnline={isCheckingTicketOnline}
      />
      {audioComponent}
    </>
  );
};
